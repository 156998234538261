.w-items-wrapper{
    display: flex;
    flex-wrap: wrap;
}

.watermark-item-wrapper{
    width: 250px;
    border-radius: 5px;
    padding: 10px;
    background-color: #f2f2f2;
    margin-right: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.watermark-item-wrapper.default{
    border: 2px solid #0084ff;
}

.watermark-item-wrapper img{
    width: 100%;
}

.w-btns{
    display: flex;
    align-items: center;
}

.w-btns button{
    width: 100%;
}
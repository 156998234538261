.pi{
    display: flex;
    flex-direction: column;
}

.pi-top-wrapper{
    display: flex;
    border-bottom: 1px solid #bfbfbf;
}

.pi-left img{
    width: 200px;
}

.pi-detail{
    font-size: 18px;
}

.pi-detail.title{
    font-weight: bold;
}

.pi-right{
    margin-left: 25px;
    display: flex;
    flex-direction: column;
}

.pi-bottom-wrapper{
    display: flex;
    padding: 5px;
    margin-top: 10px;
}
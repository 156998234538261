.fm-label-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.fm-spacing-height{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.fm-icon-input{
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.fm-icon-input input,
.fm-input input{
    width: 65px;
    border: none;
    outline: none;
}

.fm-input{
    padding: 8px 6px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 38px;
}

.fm-icon-input-wrapper{
    padding: 8px 6px;
    border-right: 1px solid #ced4da;
}

.fm-size-weight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.fm-size-weight select{
    height: 38px;
    width: 110px;
}

.fm-style-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fm-style-checkbox-wrapper{
    display: flex;
    align-items: center;
}

.fm-style-checkbox-wrapper label{
    margin-left: 2px;
    margin-right: 10px;
    font-weight: bold;
    font-size: 13px;
    color: #333;
}

.fm-align-and-style{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fm-text-align{
    display: flex;
    align-items: center;
}

.fm-txt-align-icon{
    margin: 0 2px;
    cursor: pointer;
    transition: .3s;
    padding: 4px;
    border-radius: 4px;
}

.fm-txt-align-icon:hover{
    background-color: #80808054;
}

.fm-txt-align-icon.active{
    background-color: #80808054;
}
.ReactTags__tagInputField{
    width: 190px;
    border-radius: 4px;
    padding: 5px;
}

.tag-wrapper.ReactTags__tag{
    background: #3F80EA;
    padding: 4px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    width: fit-content;
    align-items: center;
    margin-right: 7px;
    margin-top: 7px;
}

.ReactTags__remove{
    height: 15px;
    width: 15px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    margin-left: 6px;
    font-size: 13px;
}

.ReactTags__selected{
    display: flex;
    flex-wrap: wrap;
}

.pdf-size-flex{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.category-list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #ececec;
    cursor: grab;
    border: 2px solid transparent;
    transition: .2s;
    border-radius: 5px;
}

.category-list-item:hover{
    border-color:rgb(90, 90, 90);
}

.category-list-item::selection{
    border-color: transparent;
}


.category-list-item-name{
    width: 400px;
    font-size: 17px;
}

.category-list-item .btn-group{
    width: 200px !important;
}
.product-image-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-image-container canvas{
    width: 100%;
    height: 200px;    
}

.product-card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 350px; */
}

.product-footer{
    padding: 0 1.25rem;
}

#products-grid-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 10px;
}

.product-item-elmt{
    border: 1px solid rgb(199, 199, 199);
    border-radius: 7px;
    overflow: hidden;
    position: relative;
}

.product-item-tag-publised{
    background-color: #af52de;
    text-align: center;
    padding: 2px 5px;
    border-radius: 0 0 0 5px;
    position: absolute;
    right: 0px;
    top: 0px;
    font-weight: bold;
    color: white;
    z-index: 9;
    font-size: 12px;
}

.product-item-elmt .card{
    height: 100%;
}

.product-item-elmt .product-footer{
    padding-bottom: 10px;
}

.product-item-elmt .card-body,
.product-item-elmt .card-footer{
    background-color: #f7f7f7;
}

.productpage-filters{
    margin-bottom: 25px;
}
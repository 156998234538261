.canvas-editor-container{
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  height: fit-content;
}

.canvas {
  border: 1px solid red;
}


.input-label{
  cursor: pointer;
  border: 3px solid;
}

.editor-container-flex{
  display: flex;
}

.center-editor{
  flex: 1;
}

.left-editor{
  width: 270px;
  /* max-height: 100vh;
  overflow-y: scroll; */
}

/* Hide the scrollbar for Chrome, Safari and Opera */
/* .left-editor::-webkit-scrollbar {
  display: none;
} */

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
/* .left-editor {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
} */

.right-editor{
  width: 270px;
  /* max-height: 100vh;
  overflow-y: scroll; */
}

/* Hide the scrollbar for Chrome, Safari and Opera */
/* .right-editor::-webkit-scrollbar {
  display: none;
} */

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
/* .right-editor {
  -ms-overflow-style: none;
  scrollbar-width: none;  
} */

/* style for editor components */

.editor-padding{
  padding: 20px;
}

.editor-label-txt{
  color: #a1a1a1;
  font-weight: 500;
}

.hrLine{
  width: 100%;
  height: 3px;
  background-color: #e9e9e9;
}
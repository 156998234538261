.af-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.af-left{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    background-color: #f5f5f5;
    width: 100%;
    overflow: hidden;
    margin-right: 10px;
    cursor: pointer;
    transition: .2s;
    border: 1px solid transparent;
}

.af-left:hover{
    border-color: #bbbbbb;
}

.af-color{
    position: absolute;
    width: 10px;
    left: 0;
    bottom: 0;
    top: 0;
}

.af-right{
    display: flex;
    align-items: center;
}

.af-btn-eye{
    margin-right: 10px;
}

.af-btn-copy{
    margin-left: 10px;
}

.af-btn{
    cursor: pointer;
    color: #838383;
    transition: .2s;
}

.af-btn:hover{
    color: #000;
}

.af-text{
    padding-left: 15px;
    max-width: 136px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* active styles */

.af-wrapper.active .af-btn{
    color: #000;
}

.af-current-obj{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ae51dd;
    margin-right: 10px;
}
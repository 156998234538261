.dp__container{
    background-color: #fff;
    font-family: 'Nexanew2';
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.dp__title{
    text-align: center;
    font-family: 'Nexanew2';
    font-weight: 600;
    font-size: 30px;
    color: #000;
}

.dp__flex-wrapper{
    display: flex;
    width: 100%;
    padding: 30px;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.dp__left{
    width: 600px;
    height: fit-content;
    border-radius: 7px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dp__left, .dp__right{
    margin: 0 15px;
}

.dp__buttons{
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
}

#dp__download-overlay{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #c4c4c491;
    border-radius: 7px;
    z-index: 2;
}

.dp__format-button{
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
    transition: .1s;
    position: relative;
    padding: 9px 16px;
    background-color: #ba26ff;
}

.dp__format-button:hover{
    background-color: #dcdcdc;
}

.dp__format-button:hover .dp__format-text{
    color: #0f0f0f;
}

.dp__format-button:hover img{
    filter: invert(0); 
}

.dp__format-button.disabled{
    cursor: progress;
    background-color: #f2f2f2;
}

/* svg black image to white */
.dp__format-button img{
    filter: invert(1); 
    margin-right: 10px;
}

.dp__format-title{
    font-weight: bold;
    text-align: center;
    font-size: 16px;
}

.dp__format-text{
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin-top: 6px;
    font-family: 'Nexanew2';
}


#video-load-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(79 79 79 / 38%);
    border-radius: 10px;
}


.dp__btn-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dp__btn-item-description{
    font-family: 'Nexanew2';
    font-weight: 300;
}


.dp_icon_btn_img{
    width: 20px !important;
    height: 20px !important;
}

@media screen and (max-width: 500px){
    .dp__format-button{
        padding: 9px 12px !important;
    }
    .dp__format-text{
        font-size: 14px !important;
        margin-top: 2px !important;
    }
    .dp__format-button img{
        margin-left: 3px !important;
        margin-right: 5px !important;
    }
    /* NOTE add this to global wordpress css */
    .dp__btn-item-description{
        font-size: 14px !important;
    }
}

@media screen and (max-width: 375px){
    .dp__title{
        font-size: 24px;
    }
    .dp__buttons{
        padding: 10px;
    }
    .dp__container{
        justify-content: space-around;
    }
    .dp__format-button{
        padding: 7px 19px !important;
        margin: 7px;
    }
    .dp__btn-item-description{
        font-size: 14px !important;
    }
}
.sidebar-logo-wrapper{
    padding: 0 20px;
}

.sidebar-logo{
    background: white;
    padding: 10px 0;
    margin-top: 39px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.sidebar-logo svg{
    width: 200px;
    padding: 10px;
}

.sidebar-content{
    background-color: #341145;
}

.float-right{
    float: right !important;
}

.no-padding{
    padding: 0 !important;
}